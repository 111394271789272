// @flow
import * as React from 'react'
// import { useTranslation } from 'react-i18next'

// import { Store } from 'Store'
import withTheme from 'hoc/withTheme'
// import transDomain from './translations/index.translations'

import withForm from 'components/Form/Helpers/FormHOC'

import styles from './styles.module.scss'

import supportedThemes from './themes/__supportedThemes'

DonationSummary.defaultProps = {
    date: new Date()
}

function DonationSummary (props: Props) {
    // const { theme } = props
    // const { t } = useTranslation(transDomain)
    // const { state } = React.useContext(Store)
    return (
        <div className={styles.container}>
            <div className={styles.donationBox}>
                <span className={styles.amount}>2,532</span>
                <span className={styles.text}>Separate donations in 2019</span>
            </div>
            <div className={styles.donationBox}>
                <span className={styles.amount}>1,896</span>
                <span className={styles.text}>Donors that were alumni</span>
            </div>
            <div className={styles.donationBox}>
                <span className={styles.amount}>$370,000</span>
                <span className={styles.text}>Total gifts to our fund</span>
            </div>
        </div>
    )
}

export default withForm()(withTheme(supportedThemes)(DonationSummary))

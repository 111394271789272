// @flow

import * as React from "react";

import styles from "./styles.module.scss";
import { Store } from 'Store'
import withForm from 'components/Form/Helpers/FormHOC'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'

const Footer = (props) => {
  const { state } = React.useContext(Store)
  const address = state.campaign.client.address

  return (
    <>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <p className={styles.disclaimer}>
            Jonathan, thank you for helping us <strong>change lives.</strong>
          </p>
        </div>
      </div>
      <p className={styles.text}>
        <span>{[((address.line1 || '') + ' ' + (address.line2 || '')).trim(),
          address.city,
          address.province,
          address.postalCode
        ].join(' ')} {state.campaign.contactInfo.phoneNumber ? `| phone: ${state.campaign.contactInfo.phoneNumber}` : null} {state.campaign.contactInfo.email ? `| email: ${state.campaign.contactInfo.email}` : null}</span>
      </p>
    </>
  );
};

export default withForm()(withTheme(supportedThemes)(Footer))

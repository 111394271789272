import React from 'react'
import Swiper from 'react-id-swiper'
import { Store } from 'Store'

import BG from './images/bg.png'

import styles from './styles.module.scss'

export default function SideBar () {
    const { state } = React.useContext(Store)
    const name = state.lead.fields.firstName

    return (
        <div className={styles.sideBarContainer}>
            <h2>
                {name}, your donation helps to make a difference in our community.
            </h2>

            <div>
                <Swiper>
                    <span className={styles.box}>
                        <div>
                            <p>
                                “Lorem ipsum dolor sit amet,
                                consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.”
                            </p>
                            <span>Rachel Thompson, ‘20</span>
                        </div>
                    </span>
                </Swiper>
            </div>

            <div className={styles.bg} style={{ backgroundImage: `url(${BG})` }} />
        </div>
    )
}

import React from 'react'
import SwiperContainer from 'components/SwiperContainer'

import styles from './styles.module.scss'

export default function DonationSlider () {
    return (
        <div className={styles.container}>
            <SwiperContainer>
                <div className={styles.donationBox}>
                    <span className={styles.amount}>2,532</span>
                    <span className={styles.text}>Separate donations in 2019</span>
                </div>
                <div className={styles.donationBox}>
                    <span className={styles.amount}>1,896</span>
                    <span className={styles.text}>Donors that were alumni</span>
                </div>
                <div className={styles.donationBox}>
                    <span className={styles.amount}>$370,000</span>
                    <span className={styles.text}>Total gifts to our fund</span>
                </div>
            </SwiperContainer>
        </div>
    )
}

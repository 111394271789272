export default {
    typeIdentifier: 'non_profit_2021_2',
    lead: {
        fields: {
            firstName: 'John',
            lastName: 'Shifty',
            province: 'FL',
            postalCode: '33160',
            previousDonationAmount: null,
            donationAmount: null,
            email: 'john.shifty@shiftone.io',
            dayPhone: '(555) 123 5678',
            classYear: 2005,
            addressLine1: '1 Graduate Street',
            city: 'Alma Mater',
            homePhone: '(123) 123-1231'
        }
    },
    campaign: {
        // 'payment-gateways': {
        //     'mock': {}
        //     "authorize.net": {
        //         apiLoginID: "3Rqc73Yz",
        //         clientKey: "5MHjmrNGXdJ94x8zLBC9LTpDhV3q3hcac6TPS5PKYcCc83khEn8Kf5N9s222FfGX"
        //     }
        //     cashnet: {
        //         storeUrl: "https://train.cashnet.com/stockadecheckouttest",
        //         itemCode: "STOCKADE"
        //     }
        //     blackbaud: {
        //         publicKey: "3516f521-d79b-4cd1-9915-6c41a8b1bc67",
        //         merchantId: "bed81fce-c611-49b7-b200-5806c5694128"
        //     }
        //     nmi: {
        //         tokenizationKey: 'nVtwzU-TFhf85-Sen3Kx-2PAybA'
        //     }
        //     redirect: {
        //         url: "https://shiftone.io"
        //     }
        //     stripe: {
        //         publishableKey: "pk_test_hDv1iHqf9WxqYRSuEa7rrEdf00PmeyOhSN"
        //     }
        //     paypal: {
        //         clientId: "Aa9p4gbouIxIEp4oB_fGHuRtxUqM5XPNYWKmKSiLIusbzbtwbPqbs4c-eA0L3PEpLxnb-AM_G5mn8mYW",
        //         merchantId: "2"
        //     }
        // },
        // website: {
        //     'stats': [{
        //         'label': 'Total gifts donated from alumni, employees, students and friends',
        //         'value': '$15,646,734',
        //         'highlight': true
        //     }, { 'label': 'Separate Donations in 2018 - 2019', 'value': '4,790', 'highlight': false }, {
        //         'label': 'of those donors were Alumni ',
        //         'value': '3,726',
        //         'highlight': false
        //     }, { 'label': 'in scholarships awarded', 'value': '$1,975,000', 'highlight': false }],
        //     'sidebar': { 'stats': { 'description': 'Scholarships are awarded to both part-time and full-time students in all undergraduate and graduate programs' } },
        //     'masthead': {
        //         'heading': 'Thank you {{ lead.fields.firstName }} for helping make such a big difference in the lives of our students.',
        //         'message': 'The incredible support we receive from alumni and friends like you have allowed our students to focus on their studies while becoming incredible leaders in our community and building a boundless future.'
        //     },
        //     'pageFooter': { 'message': 'With your help, we can Build a Boundless Future.' },
        //     'leadGenForm': { 'description': 'Please consider a gift today in support of our students’ goals.' },
        //     donationAmounts: [500, 250, 150, 100, 75, 50],
        //     'donationDesignations': ['COVID-19 Student Emergency Fund', 'Detroit Mercy Annual Fund (area of greatest need)', 'School of Architecture', 'College of Engineering and Science', 'College of Business Administration', 'College of Health Professions', 'McAuley School of Nursing', 'School of Dentistry', 'School of Law', 'College of Liberal Arts and Education', 'Alumni Scholarship Fund', 'Athletics', 'Other (indicate in the comment box below)']
        // }
    }
}

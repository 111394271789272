import React, { useCallback } from 'react'

import { Redirect } from 'react-router-dom'
import { Currency } from 'i18n/NumberFormatter'

import { withTranslation } from 'react-i18next'
import transDomain from './translations/index.translations'

import ModalContainer from 'components/ModalContainer'
import { updateLead } from 'Actions'
import { Store } from 'Store'
import Step2 from 'components/LeadGenForm/Step2'
import Footer from 'components/Footer'
import cn from 'classnames'
import Button from 'components/Button'

import styles from './styles.module.scss'

import LOGO from './images/logo.png'
import BACKGROUND from './images/background.png'
import LOGO_MODAL from './images/logo_modal.png'

const Payment = ({
    location,
    match,
    history,
    t
}) => {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)
    const [modalActive, setShowModal] = React.useState(false)
    const currencyFormatter = Currency(state.currency, state.locale)

    const handleClick = useCallback(
        () => {
            history.push(`/${match.params.url}${location.search}`)
            window.scrollTo(0, 0)
        },
        [ history, location, match ]
    )

    React.useEffect(() => {
        if (!state.lead || !state.lead.fields) return null

        if (submitted) {
            setShowModal(true)
        } else {
            setShowModal(false)
        }
    }, [ submitted, state.lead ])

    if (!state.lead || !state.lead.fields) {
        return <Redirect to={`/${match.params.url}${location.search}`} push />
    }

    const name = state.lead.fields.firstName
    const address = state.campaign.client.address

    const {
        donationAmount,
        donationType,
        matchingGiftCompany,
        matchingGiftCompanyName,
        donationDesignations
    } = state.lead.fields

    let matchingGift = null

    if ((matchingGiftCompany === 'spouse-company' || matchingGiftCompany === 'own-company') && matchingGiftCompany !== null && matchingGiftCompany !== 'none') {
        matchingGift = matchingGiftCompanyName
    } else {
        matchingGift = matchingGiftCompany
    }

    return (
        <>
            {modalActive &&
                <ModalContainer
                    show={modalActive}>
                    <div className={styles.body}>
                        <div className={styles.topImage} style={{
                            backgroundImage: `url(${BACKGROUND})`
                        }}>
                            <div className={styles.modalLogo} style={{
                                backgroundImage: `url(${LOGO_MODAL})`
                            }} />
                        </div>
                        <div className={styles.description}>
                            <h2>Thank you, {name}.</h2>
                            <p>Your {currencyFormatter.format(donationAmount)} donation has been submitted.</p>
                            <Button
                                className={styles.btn}
                                onClick={handleClick}
                            >
                                End your session
                            </Button>
                        </div>
                    </div>
                </ModalContainer>
            }
            <div className={styles.topBar}>
                <span>{[((address.line1 || '') + ' ' + (address.line2 || '')).trim(),
                    address.city,
                    address.province,
                    address.postalCode
                ].join(' ')} {state.campaign.contactInfo.phoneNumber ? `| phone: ${state.campaign.contactInfo.phoneNumber}` : null} {state.campaign.contactInfo.email ? `| email: ${state.campaign.contactInfo.email}` : null}</span>
            </div>
            <div className={styles.bg}>
                <div className={styles.logo}>
                    <div style={{
                        backgroundImage: `url(${LOGO})`
                    }} />
                </div>
                <h2 className={styles.title}>
                    Thank you, {name}. With your {currencyFormatter.format(donationAmount)} donation, we can help make students' dreams come true.
                </h2>
                <p className={styles.p}>
                    Please fill out the payment form below to complete your donation.
                </p>
            </div>
            <div className={styles.grid}>
                <div className={styles.leftSide}>
                    <Step2
                        onSubmit={async (data, done) => {
                            try {
                                let lead = {
                                    fields: data,
                                    isComplete: false
                                }
                                await updateLead(dispatch, state.code, lead)
                                setSubmitted(true)
                                window.scrollTo(0, 0)
                            } catch (err) {
                                alert('Oops something went wrong')
                                console.error(err)
                                done(err)
                            }
                        }}
                        initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
                    />
                </div>

                <div className={styles.rightSide}>
                    <div className={styles.sideBarContainer}>
                        <h2>
                            Donation information
                        </h2>

                        <div className={styles.donationBox}>
                            <h4>Donation amount</h4>
                            <div>{currencyFormatter.format(donationAmount)}</div>
                        </div>

                        <div className={styles.donationSummary}>
                            <strong>Donation applied to:</strong>
                            <div>{donationDesignations ? donationDesignations[0] : ''}</div>
                        </div>

                        <div className={styles.donationSummary}>
                            <strong>Donation type:</strong>
                            <div>{t(`donateOptionGroup.${donationType}`)}</div>
                        </div>

                        <div className={styles.donationSummary}>
                            <strong>Matching gift:</strong>
                            <div>{matchingGift}</div>
                        </div>

                        <div className={cn(styles.donationSummary, styles.donationSummaryBox)}>
                            <strong>Jonathan Smitherson, ’20</strong>
                        </div>
                        <div className={cn(styles.donationSummary, styles.donationSummaryBox)}>
                            <strong>555 Example Drive, Apt. 204</strong>
                        </div>
                        <div className={cn(styles.donationSummary, styles.donationSummaryBox)}>
                            <strong>London, Ontario  N87 UY9</strong>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default withTranslation(transDomain)(Payment)

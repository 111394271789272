// @flow
import * as React from 'react'
// import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
// import transDomain from './translations/index.translations'
import { Store } from 'Store'

import withForm from 'components/Form/Helpers/FormHOC'

import styles from './styles.module.scss'

import LOGO from './images/logo.png'
import R_SIDE from './images/r_side.png'
import PLAY from './images/play.png'
// import SIGNATURE from './images/signature.png'

import supportedThemes from './themes/__supportedThemes'

Header.defaultProps = {
    date: new Date()
}

function Header (props) {
    // const { theme } = props
    // const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)
    const name = state.lead.fields.firstName
    const address = state.campaign.client.address

    return (
        <div className={styles.container}>
            <div className={styles.topBar}>
                <span>{[((address.line1 || '') + ' ' + (address.line2 || '')).trim(),
                    address.city,
                    address.province,
                    address.postalCode
                ].join(' ')} {state.campaign.contactInfo.phoneNumber ? `| phone: ${state.campaign.contactInfo.phoneNumber}` : null} {state.campaign.contactInfo.email ? `| email: ${state.campaign.contactInfo.email}` : null}</span>
            </div>
            <div className={styles.containerGrid}>
                <div className={styles.leftSide}>
                    <div className={styles.imgContainer}>
                        <span style={{
                            backgroundImage: `url(${LOGO})`
                        }} />
                    </div>
                    <h2 className={styles.title}>
                        {name}, thank you for helping us <strong>change lives.</strong>
                    </h2>
                    <p className={styles.p}>
                        Support from alumni, community and corporate sponsors have provided countless opportunities for our students to thrive. Thank you so very much for your generosity.
                    </p>
                </div>
                <div className={styles.img} style={{
                    backgroundImage: `url(${R_SIDE})`
                }}>
                    <span className={styles.imgLayer}>
                        <div className={styles.playLogo} style={{
                            backgroundImage: `url(${PLAY})`
                        }} />
                        {/* <div className={styles.signatureBox}>
                            <span className={styles.rawText}>
                                Rachel Thompson, ‘20
                            </span>
                            <div className={styles.signature} style={{
                                backgroundImage: `url(${SIGNATURE})`
                            }} />
                        </div> */}
                    </span>
                </div>
            </div>
            {props.children}
        </div>
    )
}

export default withForm()(withTheme(supportedThemes)(Header))
